<template>
  <v-card elevation="6">
    <v-card-title class="pt-2 pb-2">
      <div>
        <h2 class="main_title font-weight-regular" v-text="value.title" />

        <v-row align="center" class="grey--text text--lighten-1 mx-0 py-3">
          <span class="sub_title" v-text="value.subTitle" />
        </v-row>
      </div>
    </v-card-title>

    <router-link :to="`/article/${value.id}`">
      <v-img :src="require(`@/assets/${value.img}`)" height="250" />
    </router-link>
  </v-card>
</template>

<script>
export default {
  name: "HomeArticleCard",

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped>
.main_title {
  font-size: 1.2rem;
}
.sub_title {
  font-size: 0.95rem;
}

@media (max-width: 600px) {
  .main_title {
    font-size: 1.1rem;
  }
  .sub_title {
    font-size: 0.9rem;
  }
}
</style>
